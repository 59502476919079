<template>
  <div class="popup-wrapper">
    <div class="popup">
      <div class="popup__left">
        <div class="popup__icon">
          <div
            class="icon-button icon-button--rounded"
            :class="{'icon-button--rounded--orange': marker.type === 'drop-off'}"
          >
            <i v-if="marker.type === 'pickup'" class="ri-arrow-down-circle-line"></i>
            <i v-else class="ri-arrow-up-circle-line"></i>
          </div>
        </div>
      </div>
      <div class="popup__right">
        <div class="popup__item popup__item-main">
          <div class="popup__info" :class="{'popup__info-top': marker.attachmentPath}">
            <div class="popup__title map-link__popup__title">
              {{ $dots(marker.customerName, 60) }}
              <a target="_blank" class="map-link" :href="generateMapLink(marker.lat, marker.lng)">External link</a>
            </div>
            <div class="popup__address">{{ marker.customerAddress }}</div>
            <div v-if="marker.attachmentPath" class="popup__img">
              <el-image :src="asset" :preview-src-list="[marker.attachmentPath]"> </el-image>
            </div>
          </div>
        </div>
        <div v-for="(customer, i) in customers" :key="'cus' + i + customer.id" class="popup__item">
          <div class="popup__item-top">
            <div class="popup__icon">
              <div
                class="icon-button icon-button--round"
                :style="{borderColor: customer.serviceColor, backgroundColor: customer.serviceColor, color: '#fff'}"
              >
                <i class="ri-arrow-down-circle-line"></i>
              </div>
            </div>
            <div class="popup__info">
              <div class="popup__title">{{ $dots(customer.name, 24, false) }}</div>
              <div class="popup__address">{{ $dots(customer.address, 30, false) }}</div>
            </div>
            <div v-if="marker.type === 'pickup'" class="popup__btn">
              <button v-if="!customer.status" class="button button--done" @click="changeStatus(1, customer.deliveryID)">
                <i class="ri-checkbox-circle-fill"></i>
                <span>Not Done</span>
              </button>
              <button v-else class="button button--fill button--green">
                <i class="ri-checkbox-circle-fill"></i>
                <span>Done</span>
              </button>
              <i v-if="!customer.status" class="ri-more-2-fill"></i>
              <el-dropdown v-else trigger="click" @command="action">
                <i class="ri-more-2-fill"></i>
                <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                  <el-dropdown-item class="item--edit" :command="{id: customer.deliveryID, status: 0}">
                    <i class="ri-arrow-go-back-fill"></i>
                    Undo
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-else class="popup__btn">
              <button
                class="button button--done"
                :class="{'button--disabled': customer.status !== 1, 'button--green': customer.status === 2}"
                :disabled="customer.status === 1 ? false : true"
                @click="changeStatus(2, customer.deliveryID)"
              >
                <i class="ri-checkbox-circle-fill"></i>
                <span v-if="customer.status === 2">Done</span>
                <span v-else>Not Done</span>
              </button>
              <i v-if="customer.status !== 2" class="ri-more-2-fill"></i>
              <el-dropdown v-else trigger="click" @command="action">
                <i class="ri-more-2-fill"></i>
                <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                  <el-dropdown-item class="item--edit" :command="{id: customer.deliveryID, status: 1}">
                    <i class="ri-arrow-go-back-fill"></i>
                    Undo
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="popup__item-bottom" :class="{'popup__item-bottom--show': customer.active}">
            <div class="popup__item-bottom-header" @click="showDetails(customer)">
              <div class="popup__item-bottom-title">Delivery Details</div>
              <div class="popup__item-bottom-icon"><i class="ri-arrow-down-s-line"></i></div>
            </div>
            <div class="popup__item-bottom-main">
              <div v-if="customer.notes" class="popup__item-bottom-notes">{{ customer.notes }}</div>
              <el-image
                v-if="customer.attachmentPath"
                class="popup__item-bottom-image"
                :src="customer.attachmentPath"
                :preview-src-list="[customer.attachmentPath]"
                fit="cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const asset = require('@/assets/icons/camera.svg')
export default {
  name: 'MapPopupMobile',
  props: {
    marker: {type: Object, required: true, default: null}
  },

  data: function() {
    return {
      id: null,
      isLoading: true,
      sortedCustomers: [],
      asset,
      customers: this.marker.routes.map(r => {
        return {
          ...r,
          active: false
        }
      })
    }
  },
  computed: {
    maxCharacters() {
      const deviceWidth = document.documentElement.clientWidth
      if (deviceWidth > 375) {
        return 27
      } else if (deviceWidth <= 375 && deviceWidth !== 320) {
        return 22
      } else if (deviceWidth === 320) {
        return 14
      } else {
        return 20
      }
    }
  },
  methods: {
    generateMapLink(latitude, longitude, zoom = 8) {
      const baseGoogleMapsUrl = 'https://www.google.com/maps?q=';
      const coordinates = `${latitude},${longitude}`;

      return `${baseGoogleMapsUrl}${coordinates}&z=${zoom}`;
  },
    async changeStatus(status, id) {
      try {
        await this.$store.dispatch('deliveries/updateStatus', {id, status})
        const changedCustomer = this.customers.find(c => c.deliveryID === id)
        changedCustomer.status = status
      } finally {
        this.$emit('reload')
      }
    },
    action(act) {
      const {id, status} = act
      this.changeStatus(status, id)
    },
    closePopup() {
      this.$emit('hideMobile')
    },
    showDetails(c) {
      if (c.notes || c.attachmentPath) c.active = !c.active
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
