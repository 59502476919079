<template>
  <div class="map__wrapper">
    <div v-if="isLoading">
      Fancy loader should be here
    </div>

    <div v-else-if="isError">
      {{ error }}
    </div>

    <div ref="map" class="map__inner"></div>

    <map-popup
      v-if="marker && !showMobilePopup"
      :key="marker.markerID"
      :marker="marker"
      :position="position"
      :style="{top: position.y, left: position.x}"
      @closeModal="hide"
      @reload="loadMarkers"
    />

    <el-drawer
      class="el--mobile"
      :visible.sync="showMobilePopup"
      direction="btt"
      :show-close="false"
      :with-header="false"
      destroy-on-close
    >
      <map-popup-mobile :marker="marker" @hideMobile="hideMobile" @reload="loadMarkers" />
    </el-drawer>
  </div>
</template>

<script>
import MapPopup from './MapPopup'
import MapPopupMobile from './MapPopMobile'
import {Loader} from '@googlemaps/js-api-loader'
import _ from 'lodash'

const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  mapIds: ['de2da045eb64b7a']
})

export default {
  name: 'Map',
  components: {
    MapPopup,
    MapPopupMobile
  },
  data() {
    return {
      isLoading: true,
      error: null,
      map: null,
      marker: null,
      position: {},
      showMobilePopup: false
    }
  },
  computed: {
    isError() {
      return this.error !== null
    },
    markers() {
      return this.$store.getters['map/markers']
    }
  },
  async mounted() {
    await this.reloadMap()
    await this.loadMarkers()
    const domElements = ['html', 'body', '#app', '.admin-layout']
    domElements.forEach(element => {
      document.querySelector(element).style.height = '100%'
    })
  },
  methods: {
    async reloadMap() {
      try {
        this.error = null
        this.isLoading = true
        await loader.load()
        this.map = new google.maps.Map(this.$refs.map, {
          center: {
            lat: 43.716317,
            lng: -80.603254
          },
          zoom: 8,
          disableDefaultUI: true,
          gestureHandling: 'greedy'
        })
      } catch (err) {
        console.error(err)
        this.error = err.message
      } finally {
        this.isLoading = false
      }
    },
    async loadMarkers() {
      await this.$store.dispatch('map/fetchMarkers')
      const markers = []
      for (const coords of this.markers) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(coords.lat, coords.lng),
          type: coords.type,
          lat: coords.lat,
          lng: coords.lng,
          icon: this.iconForMarker(coords),
          map: this.map,
          markerID: coords.id,
          routes: coords.routes,
          customerName: coords.name,
          customerAddress: coords.address,
          attachmentPath: coords.attachmentPath
        })
        marker.addListener('click', event => {
          this.showDelivery(marker, event)
        })
        markers.push(marker)
      }
    },
    showDelivery(marker, event) {
      this.marker = marker
      const mobile = document.documentElement.clientWidth <= 768
      if (mobile) {
        this.showMobilePopup = true
      } else {
        let wCenter = window.innerWidth / 2
        let hCenter = window.innerHeight / 2
        let coordX = event.domEvent.clientX
        let coordY = event.domEvent.clientY

        if (coordX < wCenter && coordY < hCenter) {
          this.position = {
            y: coordY + 'px',
            x: coordX + 'px'
          }
        }
        if (coordX > wCenter && coordY < hCenter) {
          this.position = {
            y: coordY + 'px',
            x: coordX - 375 + 'px'
          }
        }
        if (coordX < wCenter && coordY > hCenter) {
          this.position = {
            y: coordY - 200 + 'px',
            x: coordX + 'px'
          }
        }
        if (coordX > wCenter && coordY > hCenter) {
          this.position = {
            y: coordY - 200 + 'px',
            x: coordX - 375 + 'px'
          }
        }
      }
    },
    iconForMarker(marker) {
      const svgBlue = `<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 27C14.584 27 11 23.416 11 19C11 14.584 14.584 11 19 11C23.416 11 27 14.584 27 19C27 23.416 23.416 27 19 27ZM19 12.6C15.464 12.6 12.6 15.464 12.6 19C12.6 22.536 15.464 25.4 19 25.4C22.536 25.4 25.4 22.536 25.4 19C25.4 15.464 22.536 12.6 19 12.6ZM18.2 19L15.8 19L19 15.8L22.2 19L19.8 19L19.8 22.2L18.2 22.2L18.2 19Z" fill="white"/>
        <rect width="38" height="38" rx="10" fill="white"/>
        <rect x="4" y="4" width="30" height="30" rx="7" fill="#00A3FF"/>
        <path d="M19 27C14.584 27 11 23.416 11 19C11 14.584 14.584 11 19 11C23.416 11 27 14.584 27 19C27 23.416 23.416 27 19 27ZM19 12.6C15.464 12.6 12.6 15.464 12.6 19C12.6 22.536 15.464 25.4 19 25.4C22.536 25.4 25.4 22.536 25.4 19C25.4 15.464 22.536 12.6 19 12.6ZM18.2 19L15.8 19L19 15.8L22.2 19L19.8 19L19.8 22.2L18.2 22.2L18.2 19Z" fill="white"/>
        </svg>
        `
      const svgOrange = `<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 27C14.584 27 11 23.416 11 19C11 14.584 14.584 11 19 11C23.416 11 27 14.584 27 19C27 23.416 23.416 27 19 27ZM19 12.6C15.464 12.6 12.6 15.464 12.6 19C12.6 22.536 15.464 25.4 19 25.4C22.536 25.4 25.4 22.536 25.4 19C25.4 15.464 22.536 12.6 19 12.6ZM18.2 19L15.8 19L19 15.8L22.2 19L19.8 19L19.8 22.2L18.2 22.2L18.2 19Z" fill="white"/>
        <rect width="38" height="38" rx="10" fill="white"/>
        <rect x="4" y="4" width="30" height="30" rx="7" fill="#FF5C00"/>
        <path d="M19 11C23.416 11 27 14.584 27 19C27 23.416 23.416 27 19 27C14.584 27 11 23.416 11 19C11 14.584 14.584 11 19 11ZM19 25.4C22.536 25.4 25.4 22.536 25.4 19C25.4 15.464 22.536 12.6 19 12.6C15.464 12.6 12.6 15.464 12.6 19C12.6 22.536 15.464 25.4 19 25.4ZM19.8 19H22.2L19 22.2L15.8 19H18.2V15.8H19.8V19Z" fill="white"/>
        </svg>
        `
      const svgOpacityBlue = `<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 27C14.584 27 11 23.416 11 19C11 14.584 14.584 11 19 11C23.416 11 27 14.584 27 19C27 23.416 23.416 27 19 27ZM19 12.6C15.464 12.6 12.6 15.464 12.6 19C12.6 22.536 15.464 25.4 19 25.4C22.536 25.4 25.4 22.536 25.4 19C25.4 15.464 22.536 12.6 19 12.6ZM18.2 19L15.8 19L19 15.8L22.2 19L19.8 19L19.8 22.2L18.2 22.2L18.2 19Z" fill="white" fill-opacity="0.4"/>
        <rect width="38" height="38" rx="10" fill="white" fill-opacity="0.4"/>
        <rect x="4" y="4" width="30" height="30" rx="7" fill="#438DF5" fill-opacity="0.4"/>
        <path d="M19 27C14.584 27 11 23.416 11 19C11 14.584 14.584 11 19 11C23.416 11 27 14.584 27 19C27 23.416 23.416 27 19 27ZM19 12.6C15.464 12.6 12.6 15.464 12.6 19C12.6 22.536 15.464 25.4 19 25.4C22.536 25.4 25.4 22.536 25.4 19C25.4 15.464 22.536 12.6 19 12.6ZM18.2 19L15.8 19L19 15.8L22.2 19L19.8 19L19.8 22.2L18.2 22.2L18.2 19Z" fill="white" fill-opacity="0.4"/>
        </svg>
        `
      const svgOpacityOrange = `<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 27C14.584 27 11 23.416 11 19C11 14.584 14.584 11 19 11C23.416 11 27 14.584 27 19C27 23.416 23.416 27 19 27ZM19 12.6C15.464 12.6 12.6 15.464 12.6 19C12.6 22.536 15.464 25.4 19 25.4C22.536 25.4 25.4 22.536 25.4 19C25.4 15.464 22.536 12.6 19 12.6ZM18.2 19L15.8 19L19 15.8L22.2 19L19.8 19L19.8 22.2L18.2 22.2L18.2 19Z" fill="white" fill-opacity="0.4"/>
        <rect width="38" height="38" rx="10" fill="white" fill-opacity="0.4"/>
        <rect x="4" y="4" width="30" height="30" rx="7" fill="#FF5C00" fill-opacity="0.4"/>
        <path d="M19 11C23.416 11 27 14.584 27 19C27 23.416 23.416 27 19 27C14.584 27 11 23.416 11 19C11 14.584 14.584 11 19 11ZM19 25.4C22.536 25.4 25.4 22.536 25.4 19C25.4 15.464 22.536 12.6 19 12.6C15.464 12.6 12.6 15.464 12.6 19C12.6 22.536 15.464 25.4 19 25.4ZM19.8 19H22.2L19 22.2L15.8 19H18.2V15.8H19.8V19Z" fill="white" fill-opacity="0.4"/>
        </svg>
        `

      const signBlue = {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgBlue),
        size: new google.maps.Size(38, 38),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(38, 38)
      }
      const signOpacityBlue = {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgOpacityBlue),
        size: new google.maps.Size(38, 38),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(38, 38)
      }
      const signOrange = {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgOrange),
        size: new google.maps.Size(38, 38),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 38)
      }
      const signOpacityOrange = {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgOpacityOrange),
        size: new google.maps.Size(38, 38),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 38)
      }
      if (marker.type === 'pickup') {
        if (marker.routes.every(r => r.status !== 0)) {
          return signOpacityBlue
        } else {
          return signBlue
        }
      } else if (marker.type === 'drop-off') {
        if (marker.routes.every(r => r.status === 2)) {
          return signOpacityOrange
        } else {
          return signOrange
        }
      }
    },
    hide() {
      this.marker = null
    },
    hideMobile() {
      this.showMobilePopup = false
      this.marker = null
    }
  },
  beforeDestroy() {
    const domElements = ['html', 'body', '#app', '.admin-layout']
    domElements.forEach(element => {
      document.querySelector(element).style.height = null
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
